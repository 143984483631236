* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
  font-family: 'Poppins', sans-serif;
  background: #fff !important;
  overflow-x: hidden;
  overflow-y: overlay;
  z-index: 1;
}
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(170, 170, 170, 0.6);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

html {
  min-height: 100%;
  scroll-behavior: smooth;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.animate-spin {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Brutal Type';
  src: url('./fonts/BrutalType/BrutalType-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Brutal Type';
  src: url('./fonts/BrutalType/BrutalType-Medium.otf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Brutal Type';
  src: url('./fonts/BrutalType/BrutalType-Bold.otf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Brutal Type';
  src: url('./fonts/BrutalType/BrutalType-ExtraBold.otf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

