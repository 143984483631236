.toast {
    :global {
        .Toastify__toast {
            background-color: #081824;
            border-radius: 12px;
            box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.3);
        }

        .Toastify__toast-body {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
        }

        .Toastify__toast--default {
            color: #ECF4FB;
        }

        .Toastify__toast--warning {
            color: #FFE318;
        }

        .Toastify__toast--success {
            color: #52ae6c;
        }

        .toast-success-buy-ticket {
            margin-top: 100px;
            color: #5B5B7B;
            font-weight: 500;
            font-size: 16px;    
            line-height: 23px;
            background-color: #FFFFFF;
        }

        .Toastify__toast--info {
            color: #ECF4FB;
        }

        .Toastify__toast--error {
            color: #C62828;
        }

        .Toastify__close-button {
            color: white;
            align-self: center;

            svg {
                height: 18px;
                width: 18px;
            }
        }
    }
}